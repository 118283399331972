<ng-container *ngIf="vm$ | async as vm">
  <div
    [ngClass]="vm.breakpoints.mobile ? 'card-addressee-mobile' : !styleCard ? 'card-addressee'
    : styleCard && addressee.type === 3 ? 'card-addressee-transfers' : 'card-addressee'"
    (click)="selectedAddressee(addressee)" id="addresse-{{id}}"
    (keydown.enter)="$event.preventDefault()"
    [class.w-100]="styleCard"
    [class.h-100]="styleCard"
    [class.card-addressee-capture]="vm.breakpoints.mobile && styleCard">
    <div [ngClass]="hideOptions ? 'card-content-display' : 'card-content-display-hide'">
      <div class="card-layout-data">
        <header>
          <div class="d-flex col-12 align-items-center mb-3" *ngIf="showAddresseeLabel"
            [class.mb-25]="addressee.type == ownAccountType && !vm.breakpoints.mobile &&
            !addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription)"
            [class.mb-45]="addressee.type === brokerageHouseType && !vm.breakpoints.mobile"
            [class.mb-4]="addressee.type == brokerageHouseTypeThirdParties && !vm.breakpoints.mobile">
            <div class="blue-dot"></div>
            <span class="detail-s-highlight type-card font-size--02 line-height-18">{{addresseeLabel}} </span>
          </div>
          <div class="d-flex gap-3 align-content-start">
            <div class="d-flex border-icon-profile">
              <img [src]="profileImage | cdnImage" alt="profile-image" class="icon-card-addressee"
                width="24" height="24">
            </div>
            <div class="flex-fill">
              <text-ellipsis [text]="addressee.nombre" classes="header-xs text-secondary-1000"
                [styles]="styles"></text-ellipsis>
              <br *ngIf="addressee.type === brokerageHouseType">
              <text-ellipsis [text]="addressee.nombre_alias" classes="detail-m text-secondary-1000"
                [styles]="styles"></text-ellipsis>
            </div>
          </div>
        </header>
        <main [ngClass]="(addressee.type!==1 && addressee.type !== ownAccountType) ||
         addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription) ? 'class-body'
        : 'body-cp'">
          <div class="d-felx flex-column">
            <div class="d-flex flex-column account-information">
              <ng-container *ngIf="addressee.banco?.length>27 && addressee.type !== brokerageHouseTypeThirdParties">
                <text-ellipsis [text]="addressee.banco"
                  classes="detail-m-highlight font-weight-600 text-secondary-1000 margin-top-2"
                  [styles]="styles"></text-ellipsis>
              </ng-container>
              <div *ngIf="addressee.banco?.length<=27 && addressee.type !== brokerageHouseTypeThirdParties"
                class="detail-m font-weight-600 text-secondary-1000 margin-top-2 ">{{addressee.banco}}</div>
              <div *ngIf="addressee.clabe && addressee.type !== brokerageHouseTypeThirdParties"
                class="detail-m text-break"
                [class.text-style]="addressee.clabe?.length > 18">
                <span class="font-weight-600">{{validateTypeData(addressee.clabe)}}:</span>
                  {{ addressee.clabe | numberFormat :4 }}
              </div>
              <div *ngIf="addressee.type === ownAccountType"
                class="detail-m text-break">
                <span class="font-weight-600">Saldo:</span>
                  {{ addressee.saldo | currency }}
              </div>
              <div *ngIf="addressee.type === brokerageHouseType || addressee.type === brokerageHouseTypeThirdParties"
                class="detail-m clabe-max-width text-break"
                ngbTooltip="{{addressee.contrato}}">
                <span class="font-weight-600">Contrato:</span>
                  {{addressee.contrato}}
              </div>
              <div *ngIf="addressee.clabe && addressee.type === brokerageHouseTypeThirdParties && showAccount"
                class="detail-m clabe-max-width text-break"
                [class.text-style]="addressee.clabe?.length > 18">
                <span class="font-weight-600">Cuenta:</span> {{ addressee.clabe | numberFormat :4 }}
              </div>
              <div *ngIf="addressee.type!==ownAccountType && addressee.type !== brokerageHouseType"
                class="detail-m text-secondary-1000">
                <span class="font-weight-600">Límite diario:</span> {{ addressee.limite_diario | currency }}
              </div>
              <div *ngIf="addressee.type==ownAccountType &&
                addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription)" class="secondary-card ">
                <ng-container *ngIf="addressee.limite_diario!=undefined||addressee.limite_diario!=null">
                  <span class="font-weight-600">Límite diario:</span> {{addressee.limite_diario | currency }}
                </ng-container>
              </div>
              <ng-container *ngIf="beta">
                <div *ngIf="hideOptions" class="d-flex gap-2">
                  <img [src]="fileViewBlue | cdnImage" alt="file-view" class="card-file-view" width="auto"
                    height="auto">
                  <a class="cursor-pointer detail-s-highlight-hypertext-addresse text-center"
                    (click)="showModalTransferHistory()" (keydown.enter)="$event.preventDefault()">
                    Ver Historial
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="addressee.type !== ownAccountType && addressee.type !== brokerageHouseType">
                <div *ngIf="hideOptions" class="d-flex gap-2 mt-2">
                  <img [src]="edit | cdnImage" alt="file-view" class="card-file-view cursor-pointer" width="auto"
                    height="auto" (click)="showModalModifyAccount()" (keydown.enter)="$event.preventDefault()">
                  <a class="cursor-pointer detail-s-highlight-hypertext-addresse text-center"
                    (click)="showModalModifyAccount()" (keydown.enter)="$event.preventDefault()">
                    Editar
                  </a>
                </div>
              </ng-container>
              <ng-container *ngIf="addressee.type== ownAccountType &&
            addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription) && addressee.limite_diario !== null">
                <div *ngIf="hideOptions" class="d-flex gap-2 mt-2">
                  <img [src]="edit | cdnImage" alt="file-view" class="card-file-view cursor-pointer" width="auto"
                    height="auto" (click)="showModalModifyLimit()" (keydown.enter)="$event.preventDefault()">
                  <a class="cursor-pointer detail-s-highlight-hypertext-addresse text-center"
                    (click)="showModalModifyLimit()" (keydown.enter)="$event.preventDefault()">
                    Editar
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </main>
        <footer class="d-flex justify-content-center" [class.w-100]="vm.breakpointsCard.extraMobile">
          <div *ngIf="hideOptions" class="card-buttons gap-3 justify-content-center"
            [class.w-100]="vm.breakpointsCard.extraMobile" [class.pt-25]="addressee.type == ownAccountType
            && !vm.breakpoints.mobile && !addressee.tipoCuenta.toLowerCase().includes(patrimonialDescription)"
            [class.pt-45]="addressee.type === brokerageHouseType && !vm.breakpoints.mobile"
            [class.pt-3]="addressee.type === brokerageHouseTypeThirdParties && !vm.breakpoints.mobile"
            [class.mt-1]="addressee.type === brokerageHouseTypeThirdParties && !vm.breakpoints.mobile">
            <button
              class="options-card-btn"
              (click)="selectedAddressee(addressee); addresseefunction()" (keydown.enter)="$event.preventDefault()">
              <span class="typography-options">
                Traspaso
              </span>
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #AlertEdit>
  <app-modal-modify-limit (modalCall)="closeModal()" [accountSelected]="titleModal">
  </app-modal-modify-limit>
</ng-template>
