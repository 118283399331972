import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Otp } from 'src/app/interface/Opt';
import { ModalOptions } from 'src/app/interface/modal-options';
import { AppNavigation } from 'src/app/models/AppNavigation';
import { AlertService } from 'src/app/services/alert.service';
import { ModalService } from 'src/app/shared/modal.service';
import { AppMethod, AppScreen } from 'src/core/constants/AppConstants';
import { Char, ClassHTML, Constants, ElementsHTML, Keys, Length, Numbers, Regex} from 'src/core/constants/Constants';
import { Resources } from 'src/core/constants/Resources';
import { Strings } from 'src/core/constants/Strings';
import { AppBridge } from 'src/core/utils/AppBridge';
import { Utils } from 'src/core/utils/utils';
import { ModalChecksActivationComponent } from 'src/app/component/modal-checks-activation/modal-checks-activation.component';
import { AforeDataService } from 'src/app/services/afore-data.service';
import { DataCheckbookService } from 'src/app/services/data-checkbook.service';
import { ModalConfirmCheckbookDetailsComponent } from 'src/app/home/components/modal-confirm-checkbook-details/modal-confirm-checkbook-details.component';
import { HTMLConstants } from 'src/core/constants/HTMLConstants';
import { NavigationUtils } from 'src/core/utils/NavigationUtils';
import { ModalVoluntaryWithdrawalComponent } from 'src/app/component/modal-voluntary-withdrawal/modal-voluntary-withdrawal.component';
import { StateVoluntaryWithdrawalService } from 'src/app/services/state-voluntary-withdrawal.service';
import { ModalConstants } from 'src/core/constants/ModalConstants';
import { NotifyRetirementTypeService } from 'src/app/services/notify-retirement-type.service';
import { PATH } from 'src/core/constants/Path';
import { RetirementType } from 'src/core/constants/AforeConstants';
import { TokenService } from 'src/app/services/token.service';
import { AppUtils } from 'src/core/utils/AppUtils';

@Component({
  selector: 'app-form-code-security',
  templateUrl: './form-code-security.component.html',
  styleUrls: ['./form-code-security.component.css']
})
export class FormCodeSecurityComponent implements OnInit, OnDestroy {
  close: string = Resources.CLOSE_WHITE_CIRCLE;
  questionMark: string = Resources.CIRCLE_BLUE_QUESTION;
  chevronRight: string = Resources.BTN_CHEVRON_RIGHT;
  @Input() labelModal: string = ClassHTML.MeasuresLabelOriginal;
  @Input() nextView?: string;
  @Input() transferComplete?: number | string;
  @Input() dataForm: Array<string | boolean>;
  @Output() showConfirmData = new EventEmitter();
  @Input() head: string = Strings.SECURITY_CODE.TokenHead;
  @Input() paymentInsurance: string;
  @Input() dataAmount: string;
  @Input() paymentInvestment: Array<{}>;
  @Input() amountInvestment: string;
  @Input() dataInternational: Array<string>;
  @ViewChild('ModalToken', { static: true }) modalToken: ElementRef;
  @Input() classAlert: string = ClassHTML.ClassAlert;
  @Input() btnClass: string = ClassHTML.ButtonCodeSecurity;
  @Input() colorHead: string = Strings.EMPTY;
  @Input() headerImageClass: string = ClassHTML.MeasuresOriginal;
  @Input() divLabelToken: string = ClassHTML.LabelToken;
  @Input() btnContinueClass: string = ElementsHTML.CodeSecurity;
  @Input() classInput: string = ClassHTML.InputToken;
  @Input() ngClassAlert: string = Strings.EMPTY;
  @Input() ngClassIcon: string = Strings.EMPTY;
  @Input() styleIcon: string = Strings.EMPTY;
  @Input() autofocus: boolean = true;
  isApp:boolean = AppUtils.platform.isApp;
  modalHelpToken: NgbModalRef;
  token1: string = Strings.EMPTY;
  token2: string = Strings.EMPTY;
  token3: string = Strings.EMPTY;
  token4: string = Strings.EMPTY;
  token5: string = Strings.EMPTY;
  token6: string = Strings.EMPTY;
  token7: string = Strings.EMPTY;
  token8: string = Strings.EMPTY;
  alert: boolean = true;
  errorToken: boolean = false;
  errorToken1: boolean = false;
  errorToken2: boolean = false;
  errorToken3: boolean = false;
  errorToken4: boolean = false;
  errorToken5: boolean = false;
  errorToken6: boolean = false;
  errorToken7: boolean = false;
  errorToken8: boolean = false;
  regexT: RegExp = Regex.OneMoreNumbers;
  checkBookData:boolean = false;
  processStatus: boolean = false;
  confirmActivation: boolean = false;
  onlyNumbersRegex: RegExp = Regex.nonDigitsRegex;
  aforeProcess: boolean = false;
  statusEnrollment: boolean = false;
  readonly classModal: string = ModalConstants.MODAL_OPTIONS.ModalPartialWithdrawals;
  validAge: boolean = false;
  validResolution: boolean = false;
  isVoluntary: boolean = false;
  isRight: boolean = false;
  isTotal: boolean = false;
  changeSize:boolean = true;
  iconQuadClose: string = Resources.SHAPE_CLOSE;
  chevronContinue: string = Resources.SHAPE_CONTINUE;
  modalDelete: boolean = false;
  removeClass: boolean = false;
  @Input() headerToken: string = Strings.EMPTY;
  @Input() tokenContainerClass: string = Strings.EMPTY;
  @Input() modalData: {
    showOtp?: boolean;
    nextComponent: Function;
    nextModalOptions: {
      centered: boolean;
      size?: string;
      modalDialogClass: string;
    }
  };
  closeIcon: string = Resources.CLOSE_CIRCLE_BLUE;
  protected readonly RetirementType = RetirementType;
  constructor(private readonly _router: Router, private readonly modalService: ModalService, private readonly dataCheckBook: DataCheckbookService,
    private readonly aforeDataService: AforeDataService, private readonly alertService: AlertService,
    private readonly stateVoluntary: StateVoluntaryWithdrawalService, private readonly notifyRetirementType: NotifyRetirementTypeService,
    private readonly tokenService: TokenService
  ) {
    this.confirmActivation = this.dataCheckBook.statusConfirm;
    this.aforeDataService.startProcessObservable.subscribe((data) => { this.processStatus = data.value; });
    this.aforeDataService.detailWPartialObservable.subscribe((data) => { this.aforeProcess = data.value; });
    this.aforeDataService.detailWTotalObservable.subscribe((data) => { if (data.value) { this.aforeProcess = true; } });
    this.notifyRetirementType.retirementTypeObservable.subscribe((type)=>{ this.aforeProcess = true; });
    this.checkBookData = this.dataCheckBook.checkbookRequestProcess;
  }
  removePt(): void {
    this.removeClass = this._router.url === PATH.SecurityCode;
  }

  async ngOnDestroy() {
    if (AppUtils.platform.isApp) {
      try {
        await AppBridge.invoke<boolean>(AppMethod.GetToken, Constants.FALSE);
      } catch (error) {
        Utils.printLogError(error);
      }
    }
    this.modalService.setYesToken(false);
  }

  async ngOnInit() {
    this.modalDelete = this.modalService.getYesToken();
    if (AppUtils.platform.isApp ) {
      try {
        await AppBridge.invoke<boolean>(AppMethod.GetToken, Constants.TRUE);
      } catch (error) {
        const message = Utils.getErrorMsg(error);
        this.alertService.showPopupAlert({
          header: Strings.MSG_POPUP_TITLE,
          message: Utils.SERVICE_ERROR_MSG(message.msg, message.code)
        }, {
          onSuccess: async () => {
            if (AppUtils.platform.isXamarin) {
              const navigationParams: AppNavigation = {
                Data: AppScreen.Home
              };
              await AppBridge.invoke<string>(AppMethod.SendNativeScreen, navigationParams);
            }
          },
        });
      }
    }
    if (this.transferComplete === Strings.EMPTY) {
      this.transferComplete = Numbers.Zero;
    }
    if (this.head === Strings.EMPTY || this.head === undefined || this.head === null) {
      this.head = Strings.CODE_SECURITY.Title;
    }
    if(this.autofocus){
      document.getElementById(Constants.TOKEN + Numbers.One.toString())?.focus();
    }
  }


  async showConfirmAddress(otp: Otp) {
    const token = `${otp.token1}${otp.token2}${otp.token3}${otp.token4}${otp.token5}${otp.token6}${otp.token7}${otp.token8}`;
    if (!this.nextView) {
      if (this.stateVoluntary.successVoluntary) {
        this.stateVoluntary.successVoluntary = true;
        this.stateVoluntary.confirmVoluntary = false;
        this.stateVoluntary.dataVoluntaryWithdrawal.otp = token;
        this.modalService.close();
        this.modalService.open(ModalVoluntaryWithdrawalComponent, {
          centered: false,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalVoluntaryWithdrawal});
        return;
      }
      this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowConfirmAgenda] = true;
      this.dataForm[Constants.DATA_AGENDA_NATIONAL.ShowToken] = false;
      this.dataForm[Constants.DATA_AGENDA_NATIONAL.IdAgenda] = true;
      this.dataForm[Constants.DATA_AGENDA_NATIONAL.Otp] = token;
      this.showConfirmData.emit(this.dataForm);
      if (this.modalData) {
        this.modalService.close();
        this.modalService.open(this.modalData.nextComponent, this.modalData.nextModalOptions, token);
      }
      if (this.confirmActivation) {
        this.modalService.close();
        const modalOptions: ModalOptions = {
          windowClass: Constants.MODAL_OPTIONS.NoBorder,
          centered: true,
          modalDialogClass: ModalConstants.MODAL_OPTIONS.FormCodeSecurityActivation
        };
        this.modalService.open(ModalChecksActivationComponent, modalOptions, token);
      }
      if (this.checkBookData) {
        this.showModalSuccessChecks(token);
      }
      if (this.aforeProcess || this.notifyRetirementType.retirementTypeBs.value) {
        this.tokenService.otp = token;
        this.showConfirmData.emit(true);
      }
    } else { this.otherNextView(token); }
    this.checkBookFn();
  }

  otherNextView(token: string){
    this.dataForm = [];
    this.dataForm[Constants.DATA_AGENDA_NATIONAL.Otp] = token;
    this.showConfirmData.emit(this.dataForm);
    if (this.dataInternational) {
      const navParams = {
        state: {
          dataInternational: this.dataInternational,
          otp: token
        },
        replaceUrl: true
      };
      this._router.navigate([this.nextView], navParams);
    } else {
      const navParams = {
        state: {
          otpCode: token,
          service: this.transferComplete,
          listInsurance: this.paymentInsurance,
          dataAmount: this.dataAmount,
          listInvestment: this.paymentInvestment,
          amountInvestment: this.amountInvestment
        },replaceUrl: true
      };
      this.statusAfore(navParams);
    }
  }

  statusAfore(navParams: {}) {
    if (!this.processStatus) {
      this.aforeDataService.startProcessData = { value: true };
      return;
    }
    this.aforeDataService.endProcessData = { value: true };
    this._router.navigate([this.nextView], navParams);
  }

  checkBookFn() {
    this.dataCheckBook.statusConfirmOTP = false;

  }

  closeAlert() {
    this.alert = false;
    this.removePt();
  }

  validateInputs() {
    if (this.errorToken) {
      this.errorToken =
        this.errorToken8 ||
        this.errorToken7 ||
        this.errorToken6 ||
        this.errorToken5 ||
        this.errorToken4 ||
        this.errorToken3 ||
        this.errorToken2 ||
        this.errorToken1;
    }
  }

  inputToken(number: number) {
    const currentControl: HTMLElement = document.getElementById(Constants.TOKEN + number.toString());
    const nextControl: HTMLElement = document.getElementById(Constants.TOKEN + (number + Numbers.One).toString());
    const currentValue: string = currentControl[Constants.FORM_CONTROLS.Value];
    if (currentValue.length >= Length.One) {
      const valueInput = currentValue.replace(this.onlyNumbersRegex, Strings.EMPTY);
      currentControl[Constants.FORM_CONTROLS.Value] = valueInput.slice(Numbers.Zero, Constants.INPUT_TOKEN_MAX_LENGTH);
      if (nextControl && valueInput.length >= Numbers.One) {
        nextControl[Constants.FORM_CONTROLS.Value] = valueInput.slice(Constants.INPUT_TOKEN_MAX_LENGTH, Numbers.Two);
        nextControl.focus();
      }
    }
  }

  keyToken(event: KeyboardEvent, index: number, inputsToken: {
    token1: string, token2: string, token3: string,
    token4: string, token5: string, token6: string, token7: string, token8: string
  }) {
    if (this.validateInputNumber(event, index)) {
      this.validateInputs();
      const previousControl: HTMLElement = document.getElementById(Constants.TOKEN + (index - Numbers.One).toString());
      if (event.key === Keys.Backspace) {
        previousControl?.focus();
      } else if (event.key === Keys.Enter) {
        this.validateTokenCode(inputsToken);
      }
    }
  }

  valueToken1(token1: string) {this.errorToken1 = token1 === Strings.EMPTY;}
  valueToken2(token2: string) {this.errorToken2 = token2 === Strings.EMPTY; }
  valueToken3(token3: string) {this.errorToken3 = token3 === Strings.EMPTY;}
  valueToken4(token4: string) {this.errorToken4 = token4 === Strings.EMPTY;}
  valueToken5(token5: string) {this.errorToken5 = token5 === Strings.EMPTY;}
  valueToken6(token6: string) {this.errorToken6 = token6 === Strings.EMPTY;}
  valueToken7(token7: string) {this.errorToken7 = token7 === Strings.EMPTY;}
  valueToken8(token8: string) {
    this.errorToken8 = token8 === Strings.EMPTY;
    if (token8 !== Strings.EMPTY && this.errorToken) {
      this.errorToken = false;
    }
    this.validateInputs();
  }

  showModalHelpToken() {
    const modalOptions: ModalOptions = {
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeLg,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalHelpToken
    };
    this.modalHelpToken = this.modalService.open(this.modalToken, modalOptions);
    const container = document.getElementById(HTMLConstants.ID_MODAL.ModalTokenSecurity);
    NavigationUtils.navigateAndEnsureSingleModalDisplay(container, this.modalHelpToken, false);
  }

  validateInputNumber(event: Event, index?:number) {
    let isValid = true;
    const stringNumber: HTMLInputElement = event.target as HTMLInputElement;
    const eventKeyboard: KeyboardEvent = event as KeyboardEvent;
    const regex = Regex.NumbersSpace;
    if (eventKeyboard.code === Keys.Space || stringNumber.value === Char.WhiteSpace) {
      isValid = false;
    } else {
      if (stringNumber.value.length > Numbers.Zero && eventKeyboard.key !== Keys.Backspace && !regex.test(stringNumber.value)) {
        isValid = false;
        stringNumber.value = stringNumber.value.slice(Numbers.Zero, -Numbers.One);
      }
    }
    return isValid;
  }

  showModalSuccessChecks(token: string) {
    this.modalService.close();
    const modalOptions: ModalOptions = {
      windowClass: Constants.MODAL_OPTIONS.NoBorder,
      centered: true,
      size: Constants.MODAL_OPTIONS.SizeMd,
      modalDialogClass: ModalConstants.MODAL_OPTIONS.ModalConfirmCheckbookDetails
    };
    this.modalService.open(ModalConfirmCheckbookDetailsComponent, modalOptions,token);
  }

  validateTokenCode (otp: Otp){
    if ([otp.token1, otp.token2, otp.token3, otp.token4, otp.token5, otp.token6, otp.token7, otp.token8].some((t) => t === Strings.EMPTY)) {
      this.errorToken1 = otp.token1.length === Length.Empty;
      this.errorToken2 = otp.token2.length === Length.Empty;
      this.errorToken3 = otp.token3.length === Length.Empty;
      this.errorToken4 = otp.token4.length === Length.Empty;
      this.errorToken5 = otp.token5.length === Length.Empty;
      this.errorToken6 = otp.token6.length === Length.Empty;
      this.errorToken7 = otp.token7.length === Length.Empty;
      this.errorToken8 = otp.token8.length === Length.Empty;
      this.errorToken = true;
      this.btnContinueClass = ElementsHTML.ButtonError;
      this.checkBookFn();
      return;
    }
    this.showConfirmAddress(otp);
  }

  onResetModal() {
    this.errorToken = this.errorToken1 = this.errorToken2 = this.errorToken3 = this.errorToken4 =
      this.errorToken5 = this.errorToken6 = this.errorToken7 = this.errorToken8 = false;

    for(let i = Numbers.One; i<= Numbers.Eight; i++) {
      (document.getElementById(`${Constants.TOKEN}${i}`) as HTMLInputElement).value = Strings.EMPTY;
    }
  }
}
