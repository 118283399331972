import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IConfigurationApp } from 'src/app/models/AppUserModel';
import { Strings } from 'src/core/constants/Strings';
import { AppUtils } from 'src/core/utils/AppUtils';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationApp {


  private readonly _dataDefault = { isExternal: false, goBack: Strings.EMPTY, target:Strings.EMPTY, isFromLogin:false ,facepass:{Ofuscado:false,Show:false}};
  private readonly _dataConfigurationApp: BehaviorSubject<IConfigurationApp> = new BehaviorSubject<IConfigurationApp>(this._dataDefault);
  private _isFirstLoginApp: boolean = false;

  constructor( ) { }

  get configurationApp$() {
    return this._dataConfigurationApp.asObservable();
  }

  set configurationApp(data: IConfigurationApp) {
    this._dataConfigurationApp.next(data);
  }

  get configurationApp() {
    return this._dataConfigurationApp.getValue();
  }

  get isAppExternal() {
    return AppUtils.platform.isApp && this.configurationApp?.isExternal;
  }

  setFirstLoginApp(value: boolean){
    this._isFirstLoginApp = value;
  }

  getFirstLoginApp(): boolean{
    return this._isFirstLoginApp;
  }
}
